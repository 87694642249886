.logo {
    margin: 16px;
    height: 80px;
    img {
        width: 100%;
        height: auto;
    }
}

.login {
    max-width: 700px;
    margin: auto;
    padding: 16px;
    background-color: white;
}

.ant-layout {
    min-height: 100vh;
}

.site-input-addon-button .ant-input-group-addon {
    padding: 0;
    border: 0;
}