@import '~antd/dist/antd.less';
@import 'style.less';

.container {
    width: 100%;
    margin: auto;
    padding: @padding-lg 0;
}

.make-container(@minWidth, @breakpoint) {
    @media (min-width: @minWidth) {
        .container {
            max-width: @breakpoint;
        }
    }
}

.make-container(@screen-xs-min, @screen-xs);
.make-container(@screen-sm-min, @screen-sm);
.make-container(@screen-md-min, @screen-md);
.make-container(@screen-lg-min, @screen-lg);
.make-container(@screen-xl-min, @screen-xl);

@page-header-padding: 0;